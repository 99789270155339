export const Text: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5 4C1.5 3.72386 1.72386 3.5 2 3.5H4.5H7C7.27614 3.5 7.5 3.72386 7.5 4C7.5 4.27614 7.27614 4.5 7 4.5H5V9.5C5 9.77614 4.77614 10 4.5 10C4.22386 10 4 9.77614 4 9.5V4.5H2C1.72386 4.5 1.5 4.27614 1.5 4ZM10 4C10 3.72386 10.2239 3.5 10.5 3.5H21.5C21.7761 3.5 22 3.72386 22 4C22 4.27614 21.7761 4.5 21.5 4.5H10.5C10.2239 4.5 10 4.27614 10 4ZM10.5 8.5C10.2239 8.5 10 8.72386 10 9C10 9.27614 10.2239 9.5 10.5 9.5H21.5C21.7761 9.5 22 9.27614 22 9C22 8.72386 21.7761 8.5 21.5 8.5H10.5ZM2 14C2 13.7239 2.22386 13.5 2.5 13.5H21.5C21.7761 13.5 22 13.7239 22 14C22 14.2761 21.7761 14.5 21.5 14.5H2.5C2.22386 14.5 2 14.2761 2 14ZM2.5 18.5C2.22386 18.5 2 18.7239 2 19C2 19.2761 2.22386 19.5 2.5 19.5H21.5C21.7761 19.5 22 19.2761 22 19C22 18.7239 21.7761 18.5 21.5 18.5H2.5Z"
      fill="currentColor"
    />
  </svg>
);
