export const Taboola: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.6151 14.3086C14.3109 14.2946 12.802 13.3326 11.9983 11.9483C11.1865 13.3526 9.65734 14.3221 7.32232 14.3084C3.63427 14.2873 1.98382 11.8371 2.00012 9.11324C2.01574 6.38934 3.69404 3.97858 7.38209 4.00032C9.68666 4.01305 11.1956 4.96684 11.9994 6.34542C12.8114 4.94734 14.3408 3.98614 16.6749 3.9999C20.3629 4.02096 22.0134 6.45141 21.9977 9.17532C21.9821 11.8992 20.3031 14.329 16.6151 14.3086ZM16.6599 6.72313C15.2418 6.7143 15.0448 8.09459 15.0387 9.13524C15.0319 10.1752 15.2126 11.5759 16.6321 11.5847C18.0489 11.5935 18.2479 10.1942 18.2533 9.15426C18.2588 8.11293 18.0774 6.7306 16.6599 6.72313ZM7.36647 6.72422C5.94762 6.71539 5.74997 8.09568 5.74386 9.13566C5.73843 10.1756 5.9191 11.577 7.33726 11.5858C8.75543 11.5933 8.95307 10.1953 8.95987 9.154C8.96598 8.11334 8.78531 6.73169 7.36647 6.72422ZM11.8288 16.0415C8.82267 16.0239 6.06377 15.4968 3.40471 14.3087L3.38501 17.5808C6.30896 18.9604 8.61212 19.6166 11.8077 19.6356C14.7399 19.6526 17.6828 18.9264 20.3962 17.5808L20.4139 14.3087C17.3819 15.4635 14.9871 16.0599 11.8288 16.0415Z"
      fill="currentColor"
    />
  </svg>
);
