export const Mail: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 20C4.20435 20 3.44129 19.6839 2.87868 19.1213C2.31607 18.5587 2 17.7956 2 17V7C2 6.20435 2.31607 5.44129 2.87868 4.87868C3.44129 4.31607 4.20435 4 5 4H19C19.7956 4 20.5587 4.31607 21.1213 4.87868C21.6839 5.44129 22 6.20435 22 7V17C22 17.7956 21.6839 18.5587 21.1213 19.1213C20.5587 19.6839 19.7956 20 19 20H5ZM7.625 8.22C7.52313 8.13302 7.40483 8.06739 7.27711 8.027C7.1494 7.98662 7.01487 7.97231 6.88151 7.98491C6.74816 7.99752 6.61869 8.03679 6.50081 8.1004C6.38292 8.164 6.27901 8.25064 6.19525 8.35517C6.11149 8.4597 6.04959 8.58 6.01321 8.70891C5.97684 8.83783 5.96673 8.97274 5.9835 9.10564C6.00026 9.23853 6.04356 9.36671 6.11081 9.48255C6.17806 9.59839 6.2679 9.69954 6.375 9.78L10.125 12.781C10.6571 13.207 11.3184 13.4391 12 13.4391C12.6816 13.4391 13.3429 13.207 13.875 12.781L17.625 9.781C17.7276 9.69892 17.813 9.59745 17.8763 9.48237C17.9396 9.3673 17.9797 9.24087 17.9942 9.11031C18.0087 8.97975 17.9973 8.84762 17.9607 8.72145C17.9241 8.59529 17.8631 8.47756 17.781 8.375C17.6989 8.27244 17.5974 8.18705 17.4824 8.1237C17.3673 8.06035 17.2409 8.02029 17.1103 8.00581C16.9798 7.99132 16.8476 8.00269 16.7215 8.03927C16.5953 8.07585 16.4776 8.13692 16.375 8.219L12.625 11.219C12.4476 11.361 12.2272 11.4384 12 11.4384C11.7728 11.4384 11.5524 11.361 11.375 11.219L7.625 8.219V8.22Z"
      fill="white"
    />
  </svg>
);
