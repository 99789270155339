export const Checkbox: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.5 7.33333C2.5 6.39166 2.50039 5.71548 2.54375 5.18472C2.58664 4.6598 2.66906 4.32131 2.80881 4.04703C3.08046 3.5139 3.5139 3.08046 4.04703 2.80881C4.32131 2.66906 4.6598 2.58664 5.18472 2.54375C5.71548 2.50039 6.39166 2.5 7.33333 2.5H16.6667C17.6083 2.5 18.2845 2.50039 18.8153 2.54375C19.3402 2.58664 19.6787 2.66906 19.953 2.80881C20.4861 3.08046 20.9195 3.5139 21.1912 4.04703C21.3309 4.32131 21.4134 4.6598 21.4562 5.18472C21.4996 5.71548 21.5 6.39166 21.5 7.33333V16.6667C21.5 17.6083 21.4996 18.2845 21.4562 18.8153C21.4134 19.3402 21.3309 19.6787 21.1912 19.953C20.9195 20.4861 20.4861 20.9195 19.953 21.1912C19.6787 21.3309 19.3402 21.4134 18.8153 21.4562C18.2845 21.4996 17.6083 21.5 16.6667 21.5H7.33333C6.39166 21.5 5.71548 21.4996 5.18472 21.4562C4.6598 21.4134 4.32131 21.3309 4.04703 21.1912C3.5139 20.9195 3.08046 20.4861 2.80881 19.953C2.66906 19.6787 2.58664 19.3402 2.54375 18.8153C2.50039 18.2845 2.5 17.6083 2.5 16.6667V7.33333Z"
      stroke="#333333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
