export const FormatInstagramStory: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 3H14C15.1046 3 16 3.89543 16 5V19C16 20.1046 15.1046 21 14 21H10C8.89543 21 8 20.1046 8 19V5C8 3.89543 8.89543 3 10 3ZM7 5C7 3.34315 8.34315 2 10 2H14C15.6569 2 17 3.34315 17 5V19C17 20.6569 15.6569 22 14 22H10C8.34315 22 7 20.6569 7 19V5ZM2 6.5C1.72386 6.5 1.5 6.72386 1.5 7C1.5 7.27614 1.72386 7.5 2 7.5H2.01111C2.28725 7.5 2.51111 7.27614 2.51111 7C2.51111 6.72386 2.28725 6.5 2.01111 6.5H2ZM12 6.5C11.7239 6.5 11.5 6.72386 11.5 7C11.5 7.27614 11.7239 7.5 12 7.5H12.0111C12.2873 7.5 12.5111 7.27614 12.5111 7C12.5111 6.72386 12.2873 6.5 12.0111 6.5H12ZM22 6.5C21.7239 6.5 21.5 6.72386 21.5 7C21.5 7.27614 21.7239 7.5 22 7.5H22.0111C22.2873 7.5 22.5111 7.27614 22.5111 7C22.5111 6.72386 22.2873 6.5 22.0111 6.5H22ZM2 11.5C1.72386 11.5 1.5 11.7239 1.5 12C1.5 12.2761 1.72386 12.5 2 12.5H2.01111C2.28725 12.5 2.51111 12.2761 2.51111 12C2.51111 11.7239 2.28725 11.5 2.01111 11.5H2ZM12 11.5C11.7239 11.5 11.5 11.7239 11.5 12C11.5 12.2761 11.7239 12.5 12 12.5H12.0111C12.2873 12.5 12.5111 12.2761 12.5111 12C12.5111 11.7239 12.2873 11.5 12.0111 11.5H12ZM22 11.5C21.7239 11.5 21.5 11.7239 21.5 12C21.5 12.2761 21.7239 12.5 22 12.5H22.0111C22.2873 12.5 22.5111 12.2761 22.5111 12C22.5111 11.7239 22.2873 11.5 22.0111 11.5H22ZM12 16.5C11.7239 16.5 11.5 16.7239 11.5 17C11.5 17.2761 11.7239 17.5 12 17.5H12.0111C12.2873 17.5 12.5111 17.2761 12.5111 17C12.5111 16.7239 12.2873 16.5 12.0111 16.5H12ZM2 16.5C1.72386 16.5 1.5 16.7239 1.5 17C1.5 17.2761 1.72386 17.5 2 17.5H2.01111C2.28725 17.5 2.51111 17.2761 2.51111 17C2.51111 16.7239 2.28725 16.5 2.01111 16.5H2ZM22 16.5C21.7239 16.5 21.5 16.7239 21.5 17C21.5 17.2761 21.7239 17.5 22 17.5H22.0111C22.2873 17.5 22.5111 17.2761 22.5111 17C22.5111 16.7239 22.2873 16.5 22.0111 16.5H22Z"
      fill="currentColor"
    />
  </svg>
);
