export const Google: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.12 10.1999H12.12V13.7999H17.4C16.92 16.3199 14.64 17.9999 12.12 17.8799C9.00002 17.7599 6.60001 15.3599 6.48001 12.2399C6.36001 8.9999 8.88002 6.2399 12.12 6.1199C13.44 6.1199 14.76 6.5999 15.84 7.5599L18.6 4.9199C16.92 3.2399 14.52 2.3999 12.24 2.3999C6.96001 2.3999 2.64001 6.7199 2.64001 11.9999C2.64001 17.2799 6.96001 21.5999 12.24 21.5999C17.76 21.5999 21.48 17.7599 21.48 12.2399C21.36 11.5199 21.36 10.9199 21.12 10.1999Z"
      fill="white"
    />
  </svg>
);
