export const StarFilled: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1156_684)">
      <path
        d="M10.5652 2.90718C11.0262 1.97314 11.2567 1.50612 11.5696 1.35691C11.8419 1.2271 12.1581 1.2271 12.4304 1.35691C12.7433 1.50612 12.9738 1.97314 13.4348 2.90718L15.1544 6.39159C15.2907 6.66759 15.3588 6.80559 15.4583 6.9127C15.5465 7.00754 15.6522 7.08435 15.7696 7.13888C15.9023 7.20047 16.0546 7.2226 16.3591 7.26686L20.2044 7.82561C21.2352 7.97539 21.7506 8.05028 21.9892 8.30177C22.1968 8.52058 22.2945 8.8214 22.2552 9.12044C22.21 9.46414 21.837 9.82767 21.0912 10.5547L18.3087 13.267C18.0883 13.4818 17.9781 13.5892 17.907 13.717C17.844 13.8301 17.8036 13.9544 17.7881 14.083C17.7705 14.2281 17.7965 14.3798 17.8485 14.6832L18.5054 18.5129C18.6815 19.5395 18.7695 20.0528 18.604 20.3575C18.4601 20.6225 18.2042 20.8084 17.9076 20.8634C17.5668 20.9266 17.1058 20.6843 16.1839 20.1996L12.7445 18.3914C12.4721 18.2482 12.3359 18.1766 12.1924 18.1485C12.0653 18.1235 11.9347 18.1235 11.8076 18.1485C11.6641 18.1766 11.5279 18.2482 11.2555 18.3914L7.81613 20.1996C6.89418 20.6843 6.4332 20.9266 6.09235 20.8634C5.79579 20.8084 5.5399 20.6225 5.39595 20.3575C5.2305 20.0528 5.31854 19.5395 5.49461 18.5129L6.15147 14.6832C6.20349 14.3798 6.22951 14.2281 6.21192 14.083C6.19635 13.9544 6.15597 13.8301 6.09301 13.717C6.02191 13.5892 5.91171 13.4818 5.69131 13.267L2.90884 10.5547C2.16297 9.82767 1.79003 9.46414 1.74482 9.12044C1.70549 8.8214 1.80323 8.52058 2.01082 8.30177C2.24942 8.05028 2.76481 7.97539 3.79558 7.82561L7.64086 7.26686C7.94544 7.2226 8.09774 7.20047 8.23037 7.13888C8.34781 7.08435 8.45353 7.00754 8.54168 6.9127C8.64124 6.80559 8.70935 6.66759 8.84556 6.39159L10.5652 2.90718Z"
        fill="#DF4D2C"
      />
    </g>
    <defs>
      <clipPath id="clip0_1156_684">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
