export const Team: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.0001 15.8369C19.456 16.5683 20.7042 17.742 21.6153 19.2096C21.7957 19.5003 21.886 19.6456 21.9172 19.8468C21.9805 20.2558 21.7009 20.7585 21.32 20.9204C21.1326 21 20.9218 21 20.5001 21M16.0001 11.5322C17.4818 10.7959 18.5001 9.26686 18.5001 7.5C18.5001 5.73314 17.4818 4.20411 16.0001 3.46776M14.0001 7.5C14.0001 9.98528 11.9854 12 9.50008 12C7.0148 12 5.00008 9.98528 5.00008 7.5C5.00008 5.01472 7.0148 3 9.50008 3C11.9854 3 14.0001 5.01472 14.0001 7.5ZM2.55931 18.9383C4.15362 16.5446 6.66945 15 9.50008 15C12.3307 15 14.8465 16.5446 16.4409 18.9383C16.7901 19.4628 16.9648 19.725 16.9446 20.0599C16.929 20.3207 16.758 20.64 16.5496 20.7976C16.282 21 15.9139 21 15.1777 21H3.82244C3.08625 21 2.71816 21 2.45052 20.7976C2.24213 20.64 2.07117 20.3207 2.05551 20.0599C2.03541 19.725 2.21004 19.4628 2.55931 18.9383Z"
      stroke="#333333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
